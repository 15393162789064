.form-positive {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1em 0;
    font-size: 0.85em;

    &::before {
        display: inline-block;
        content: '';
        width: 1.3em;
        height: 1.3em;
        margin-right: 0.6em;

        background-color: rgba(13, 55, 78, .4);
        border: 1.5px solid $blue;
        
        border-radius: 5px;
    }

    transition: all 0.15s;

    &.active::before {
        background-color: $blue;
    }
    &.signal::before {
        border-color: $orange;
    }
}